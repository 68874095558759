import React from "react";

export function DownloadIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" stroke="currentColor" fill="none" width="1em" height="1em" viewBox="0 0 4.2333333 4.2333333" version="1.1" className="icon--download" id="download-icon">
      <g className="icon--download__base">
        <path d="M 0.9, 4 H 3.4" />
      </g>
      <g className="icon--download__arrow">
        <path d="M 2.1166667,0.8 V 3.3" />
        <path d="M 2.1166667,3.4 3.05,2.5" />
        <path d="M 2.1166667,3.4 1.2,2.5" />
      </g>
    </svg>
  );
}
