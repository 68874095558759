import React from "react";
import { graphql } from "gatsby";
import Default from "./default";
import PostLayout from "./templates/post";
import Seo from "./seo";

const Post = ({ data }) => {
  const { markdownRemark } = data;

  return (
    <>
      <Seo title={markdownRemark.frontmatter.title} description={markdownRemark.frontmatter.description} />
      <Default>
        <PostLayout
          title={markdownRemark.frontmatter.title}
          date={markdownRemark.frontmatter.date}
          type={markdownRemark.frontmatter.type}
          downloadid={markdownRemark.frontmatter.downloadid}
          filesize={markdownRemark.frontmatter.filesize}
          directdownload={markdownRemark.frontmatter.directdownload}
          slug={markdownRemark.fields.slug}
          typeid={markdownRemark.frontmatter.typeid}
          category={markdownRemark.frontmatter.category}
          categoryid={markdownRemark.frontmatter.categoryid}
          mcpedlid={markdownRemark.frontmatter.mcpedlid}
          description={markdownRemark.frontmatter.description}>
          <div dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
        </PostLayout>
      </Default>
    </>
  );
};

export default Post;

export const query = graphql`
  query PostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        type
        date(formatString: "D MMM YYYY")
        description
        downloadid
        filesize
        directdownload
        typeid
        category
        categoryid
        mcpedlid
      }
      fields {
        slug
      }
    }
  }
`;
