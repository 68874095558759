import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../templates/modal";
import { Button } from "../templates/buttons";

function DownloadModal({ title, directdownload }) {
  return (
    <Modal id="download" title="Download">
      <p>Your download of {title} has begun!</p>
      <Button link="/packs" type="pack" text="Find more packs" />
      <p className="center">
        Download not starting?{" "}
        <a className="link" href={`https://assets.quazchick.com/downloads/${directdownload}`}>
          Click Here!
        </a>
      </p>
    </Modal>
  );
}

DownloadModal.propTypes = {
  title: PropTypes.string,
  directdownload: PropTypes.string,
};

export default DownloadModal;
