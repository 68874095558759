import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ShareModal from "../modals/shareModal";
import { ModalOpenButton } from "./modal";
import DownloadModal from "../modals/downloadModal";
import { DownloadIcon } from "../icons/downloadIcon";
import { ShareIcon } from "../icons/shareIcon";
import { Link } from "gatsby";
import Breadcrumbs from "../breadcrumbs";
import { McpedlIcon } from "../icons/mcpedlIcon";

const PostLayout = ({ title, description, date, slug, type, children, downloadid, filesize, directdownload, typeid, categoryid, category, mcpedlid }) => {
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("download") === "true") {
      openDownloadModal();
    }
  });

  function openDownloadModal() {
    document.getElementById(`download-modal`).setAttribute("status", "open");
    document.getElementById(`download-modal-close`).focus();
    window.location.replace(`https://bit.ly/${downloadid}`);
  }

  return (
    <>
      <section className="section--pack">
        <Breadcrumbs>
          <Link to={`/${categoryid}`}>{category}</Link>
          <Link style={{ display: categoryid === "tutorials" ? "none" : null }} to={`/${categoryid}/${typeid}`}>
            {type}s
          </Link>
          <span>{title}</span>
        </Breadcrumbs>
        <div className="section--pack__layout">
          <div>
            <div className="section--pack__layout__header">
              <h1 title={title}>{title}</h1>
              {categoryid === "tutorials" ? null : <h2 title={date}>{date}</h2>}
            </div>
            <img src={`https://assets.quazchick.com/images/banners/` + title + ".png"} alt="" />
            <div>
              <div className="split">
                <ModalOpenButton style={{ display: categoryid === "tutorials" ? "none" : null }} id="share" className="button button--share">
                  <ShareIcon />
                  Share
                </ModalOpenButton>
                <a href={"https://mcpedl.com/" + mcpedlid} style={{ display: mcpedlid === "none" ? "none" : null }} className="button button--mcpedl">
                  <McpedlIcon />
                  View
                </a>
              </div>
              <button onClick={openDownloadModal} className="button button--download">
                Download <em>[{filesize}]</em>
                <DownloadIcon />
              </button>
            </div>

            <hr />
            <p>{description}</p>
          </div>
          <article className="post">{children}</article>
        </div>
      </section>
      <ShareModal slug={slug} title={title} type={type} />
      <DownloadModal title={title} downloadid={downloadid} directdownload={directdownload} />
    </>
  );
};

PostLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PostLayout;
